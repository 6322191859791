import { post, get, del, postFile } from "./httpuni";

export interface AuthRequest {
  username: string;
  password: string;
}
 
export const loginUni = (auth: AuthRequest) => {
  //console.group(auth)
  return get(
    `/public/student/login?customStudentId=${auth.username}&instituteId=${auth.password}`
  );
};

export const updatDataUni = (payload: any) => {
  //console.group(auth)
  return get(
    `/public/student/login?customStudentId=${payload.customStudentId}&instituteId=${payload.instituteId}`
  );
};

export const collectionListUni = (values: any) => {
  //console.group(auth)
  return get(
    `/public/online/fees/payable?instituteId=${values.instituteId}&semesterYearId=${values.semesterYearId}&studentId=${values.studentId}`
  );
};

export const submitDataFinalUni = (payload: any) => {
  return post("/public/get/online-fees-payment/spg/session-id", payload);
};
export const delInvoiceUni = (values: any) => {
  //console.group(auth)
  return del(
    `/public/fees-payment/delete/unnecessary/invoices?identificationId=${values.identificationId}`
  );
};

export const reportListUni = (values: any) => {
  //console.group(auth)
  return get(
    `/public/student/paid/invoices?instituteId=${values.instituteId}&semesterYearId=${values.semesterYearId}&studentId=${values.studentId}`
  );
};


export const updateStudentGuardianInfoUniversity = (payload: any) => {
  //console.group(auth)
  return get(
    `/public/student/mobileno/change?instituteId=${payload.instituteId}&mobileNo=${payload.mobileNo}&studentId=${payload.studentId}`
  );
};

export const updateStudentProfileBasicInfoUniversity = (payload: any) => {
  return post("/public/student/basic/info/update", payload);
};

export const updateStudentPhotoUniversity = (data, payload) => postFile(`/public/student/photo/update?instituteId=${payload.instituteId}&studentId=${payload.studentId}`, data);
export const saveStudentProfileUpdateTokenUniversity = (payload) => get(`/public/student/otp/send?instituteId=${payload.instituteId}&studentId=${payload.studentId}`);
export const otpUsedSendUniversity = (payload) => get(`/public/student/otp/check?instituteId=${payload.instituteId}&studentId=${payload.studentId}&token=${payload.token}`);