import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Row, Select, Col, Button, Table, Input, Spin, Result, List, Card, Checkbox, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../store/hooks/easyPeasy';
import { delInvoice } from '../../http/auth';
const { Option } = Select;

export default function Payment(props) {
    const user = useStoreState(state => state.auth.user)
    const loading = useStoreState(state => state.auth.loading)
    const tableData = useStoreState(state => state.auth.tableData)
    const serviceCharge = useStoreState(state => state.auth.serviceCharge)
    const collectionListUni = useStoreActions(state => state.auth.collectionListUni);
    const submitDataFinalUni = useStoreActions(state => state.auth.submitDataFinalUni);

    const [form] = Form.useForm();

    // console.log(tableData)
    var isMobile = false; //initiate as false
    if (
        /iP(hone|od)|android.+mobile|BlackBerry|IEMobile/i.test(navigator.userAgent)
    ) {
        isMobile = true;
    }
    const [selectedRowKeys, setselectedRowKeys] = useState<any>([])
    const [pageTableData, setpageTableData] = useState<any>([])
    const [submitTableData, setsubmitTableData] = useState<any>([])
    const [totalPayable, settotalPayable] = useState<any>(0)
    const [identificationId, setidentificationId] = useState<any>(0)
    const [showcharge, setshowcharge] = useState<any>(0)
    useEffect(() => {
        //  console.log("hello")
        setselectedRowKeys([])
        settotalPayable(0)
        let tableDataa = tableData?.feesList?.map(function (item, index) {
            return {
                key: index,
                feeHeadId: item?.feeHeadId,
                feeHeadName: item?.feeHeadName,
                feeSubHeadId: item?.feeSubHeadId,
                feeSubHeadName: item?.feeSubHeadName,
                feeWaiverId: item?.feeWaiverId,
                feeWaiverName: item?.feeWaiverName,
                feeAmount: item?.feeAmount,
                waiverAmount: item?.waiverAmount,
            }
        })
        setidentificationId(tableData?.identificationId)
        setpageTableData(tableDataa)
        let totalPayAbleVal = tableData?.feesList?.map(item => item.feeAmount).reduce((prev, curr) => prev + curr, 0);
        settotalPayable(totalPayAbleVal)
        setsubmitTableData(tableData?.feesList)
        let temp: any = []
        for (let i = 0; i < tableData?.feesList?.length; i++) {
            temp.push(i)
        }
        setselectedRowKeys(temp)
    }, [tableData])

    const onFinish = (values: any) => {
        //delInvoice({ identificationId: user?.identificationId });
        // console.log(user?.identificationId);
        let payload: any = {
            studentId: user?.studentId,
            instituteId: user?.instituteId,
            semesterYearId: values?.semesterYearId,
        }
        collectionListUni(payload);
    };



    const columns = [
        {
            title: 'Fee Head Name',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
        },
         {
            title: 'Payable Amount',
            dataIndex: 'feeAmount',
            key: 'feeAmount',
        },
        // {
        //     title: 'Waiver Name',
        //     dataIndex: 'waiverName',
        //     key: 'waiverName',
        // }, 
        // {
        //     title: 'Waiver Amount',
        //     dataIndex: 'waiverAmount',
        //     key: 'waiverAmount',
        // }, 
    ];
    const onSelectChange = (selectedRowKeys, value) => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setsubmitTableData(value);
        setselectedRowKeys(selectedRowKeys);

        let totalPayAbleVal = value
            .map(item => item.feeAmount)
            .reduce((prev, curr) => prev + curr, 0);
        settotalPayable(totalPayAbleVal)

    };
    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: onSelectChange,
    };

    // console.log(selectedRowKeys)

    const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

    const onPay = () => {
        let details = submitTableData.map(function (item) {
            return {
                "feeAmount": item?.feeAmount,
                "feeHeadId": item?.feeHeadId,
                "waiverAmount": item?.waiverAmount,
                "waiverId": null,
            }
        });
        // let details = tableData.map(function (item) {
        //     return {
        //         "feeAmount": item?.feeAmount,
        //         "feeHeadId": item?.feeHeadId,
        //         "feeSubHeadId": item?.feeSubHeadId,
        //         "waiverAmount": item?.waiverAmount,
        //         "waiverId": item?.feeWaiverId
        //     }
        // });

   
        let postData: any = {
            identificationId: identificationId,
            serviceCharge: serviceCharge,
            details: details
        };
        //console.log(postData)
        submitDataFinalUni(postData)
    }

    const mobileSelect = (e, item) => {
        let temp;
        if (e) {
            temp = [...submitTableData, item]
            setsubmitTableData(temp)
            let totalPayAbleVal = temp
                .map(item => item.feeAmount)
                .reduce((prev, curr) => prev + curr, 0);
            settotalPayable(totalPayAbleVal)

        }
        if (!e) {
            temp = submitTableData.filter(i => i.feeHeadId !== item.feeHeadId);
            setsubmitTableData(temp)
            let totalPayAbleVal = temp
                .map(item => item.feeAmount)
                .reduce((prev, curr) => prev + curr, 0);
            settotalPayable(totalPayAbleVal)

        }

    }

    return (
        <>
            <br />
            <div className={isMobile ? 'mobileView' : "mt-25 mb-sm-25"}>
            <Card title="Make Payment">
                <div className="loading" style={{ display: loading ? "inherit" : "none" }}> <Spin indicator={antIcon} /></div>
                <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} className="mb-sm-25" >
                    <Row gutter={15}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>

                            <Form.Item
                                name="semesterYearId"
                                label="Semester Year:"
                                className="title-Text custon-form-wrapper"
                                rules={[
                                    { required: true, message: "Please Select semester year" },
                                ]}
                            >
                                <Select allowClear  placeholder="Select Semester Year" >
                                    {user?.semesterYearList?.map((item:any, index:any) => {
                                        return (
                                            <Option value={item.id} key={index}>{item.name}</Option>
                                        )
                                    })}
                                    </Select>


                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item className="mb-sm-0">
                                <Button type="primary" className="success-button" htmlType="submit" style={{ marginTop: isMobile ? 0 : 30, width: "100%", padding: 10 }}>
                                    Search
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
            {/* <br /> */}
            {tableData?.feesList?.length > 0 && <span style={{ fontWeight: "bold", fontSize: 18, marginTop: 15, marginBottom: 10, display: "block" }}>List of fees:</span>}
            {!isMobile && <><br /></>}
            {/* <Skeleton active /> */}
            {tableData?.feesList?.length > 0 &&
                <>
                    <Card>
                        <>
                            {
                                isMobile ?
                                    <List
                                        itemLayout="vertical"
                                        dataSource={tableData?.feesList}
                                        size="large"
                                        pagination={false}


                                        renderItem={(item: any) => (
                                            <List.Item key={item.key}>
                                                <ul className="w3-ul w3-card-4 payment-fee-list">
                                                    <li className="w3-bar">
                                                        <span className="w3-bar-item w3-button w3-white w3-xlarge w3-right">{item?.feeAmount}</span>
                                                        <div className="w3-bar-item">
                                                            <div style={{ display: "flex", justifyItems: "center", alignContent: "center", alignItems: 'center' }}>
                                                                <div>
                                                                    <Checkbox defaultChecked onChange={(e) => mobileSelect(e.target.checked, item)} ></Checkbox>
                                                                </div>
                                                                <div style={{ marginLeft: 10 }}>
                                                                    <span className="w3-large">{item?.feeHeadName}</span><br />
                                                                    <span>{item?.feeSubHeadName}</span>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </li>
                                                </ul>

                                            </List.Item>
                                        )}
                                    /> :
                                    <Table
                                        bordered={true}
                                        dataSource={pageTableData}
                                        columns={columns}
                                        rowSelection={rowSelection}
                                    />
                            }
                            {isMobile &&
                                <>
                                    <span style={{ marginTop: 10, fontSize: 10, marginBottom: -10 }}> * {serviceCharge} Taka Service Charge</span>
                                    <div className="payableWrapper">
                                        <div className="totalPayable">
                                            <div className="totalPayableTitle"> Total Payable</div>
                                            <h3 className="totalPayableCount">{totalPayable+serviceCharge}</h3>
                                        </div>
                                        <div className="bg">
                                        </div>
                                    </div>

                                    <Button type="primary" className="mb-sm-25" style={{ width: isMobile ? "100%" : "100%", marginTop: isMobile ? 20 : 20, float: "right", padding: 10, height: "auto" }} disabled={totalPayable > 0 ? false : true} onClick={() => onPay()}>
                                        Pay Now
                                    </Button>
                                </>

                            }
                            
                            </>
                    </Card>
                    <>
                    {!isMobile &&
                            <Row className='mt-30'>
                                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                    <div className="payment-instruction-wrapper">
                                        <span className="text-highlight">*Marked fields are mandatory</span>
                                        {<span>{serviceCharge} Taka Service Charge </span>}
                                        {/* <span>2. XYZ</span> */}
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                    <Row className='d-flex align-items-center justify-content-between payable-wrapper'>
                                        <Col span={12} style={{ paddingRight: "6px" }}>
                                            <Form.Item className='payment-input'>
                                                <span>Total Payable:</span>
                                                <Input placeholder="Total Payable" disabled value={totalPayable+serviceCharge} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} style={{ paddingLeft: "6px" }}>
                                            <Form.Item>
                                                <div className="mb-sm-25">
                                                    <Button className='success-button' type="primary" id="mb-sm-25 demo" style={{ width: isMobile ? "100%" : "100%", marginTop: isMobile ? 20 : 20, float: "right", }} disabled={totalPayable > 0 ? false : true} onClick={() => onPay()}>
                                                        Pay
                                                    </Button>
                                                </div>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                        }
                    </>
                </>
                
            }

        </div>
        </>
    )
}
